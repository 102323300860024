<template>
  <base-material-card icon="mdi-clipboard-text" :title="listingName" class="px-5 py-3">
    <div class="listing--form-container">
      <page-loading :show="loading" />
      <form @submit.prevent="submit">
        <div class="form--group row" v-if="listingNumber">
          <label class="col-12 col-lg-3">
            <span>Listing ID</span>
          </label>
          <div class="col-12 col-lg-6">
            <div
              class="listing--number"
              id="copyToClipboardListingNumber"
              @click="copyToClipboard(listingNumber)"
            >
              <div>
                {{ `${$t('general.listingNumber')}: ${listingNumber}` }}
              </div>
              <div>
                <v-icon>mdi-content-copy</v-icon>
              </div>
            </div>
            <v-snackbar v-model="openSnackbar">
              {{ $t('general.clipboard') }}
            </v-snackbar>
          </div>
        </div>
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedPropertyType') }"
        >
          <label class="col-12 col-lg-3" for="selectedPropertyTypeField">
            <span>Tipe Properti</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedPropertyType"
              id="selectedPropertyTypeField"
              v-model="selectedPropertyType"
              :options="propertyTypes"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :disabled="readOnly"
            />
            <span class="val-error">{{ validation.firstError('selectedPropertyType') }}</span>
          </div>
        </div>
        <regional-section :readOnly="readOnly" ref="regionalSection" />
        <map-section :readOnly="readOnly" ref="mapSection" />
        <div class="form--group row" :class="{ 'has-error': validation.hasError('address') }">
          <label class="col-12 col-lg-3" for="addressField">
            <span>Alamat</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-textarea
              id="addressField"
              v-model="address"
              rows="2"
              name="address"
              outlined
              hide-details
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('address') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('categoryName') }">
          <label class="col-12 col-lg-3" for="categoryNameField">
            <span>Nama Perumahan/Cluster</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="categoryNameField"
              v-model="categoryName"
              type="text"
              name="categoryName"
              outlined
              hide-details
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('categoryName') }}</span>
          </div>
        </div>
        <unit-numbering-section :readOnly="readOnly" ref="unitNumberingSection" />
        <div class="form--group row" :class="{ 'has-error': validation.hasError('listingName') }">
          <label class="col-12 col-lg-3" for="listingNameField">
            <span>Judul Listing</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="listingNameField"
              v-model="listingName"
              type="text"
              name="listingName"
              outlined
              hide-details
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('listingName') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('listingDesc') }">
          <label class="col-12 col-lg-3" for="listingDescField">
            <span>Deskripsi</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-textarea
              id="listingDescField"
              v-model="listingDesc"
              rows="5"
              name="listingDesc"
              outlined
              hide-details
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('listingDesc') }}</span>
          </div>
        </div>
        <photo-section :readOnly="readOnly" ref="photoSection" />
        <div class="form--group row">
          <label class="col-12 col-lg-3">
            <span>Harga Properti</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <cleave
              v-model="price"
              :options="cleaveOptionCurrency"
              class="basic--input"
              name="price"
              type="text"
              placeholder="Min"
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('price') }}</span>
          </div>
        </div>

        <unit-detail-section :readOnly="readOnly" ref="unitDetailSection" />

        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('hoek') }"
          v-if="hoekRule.allowed"
        >
          <label class="col-12 col-lg-3" for="hoekField"
            ><span>{{ $t('listing.hoek') }}</span>
            <span class="required" v-show="hoekRule.required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="hoek"
              id="hoekField"
              v-model="hoek"
              :options="[1, 0]"
              :searchable="false"
              :allowEmpty="true"
              :close-on-select="true"
              :disabled="readOnly"
            >
              <template slot="option" slot-scope="{ option }">
                <span>{{ option === 1 ? 'Ya' : 'Tidak' }}</span>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option === 1 ? 'Ya' : 'Tidak' }}
              </template>
            </multiselect>
            <span class="val-error">{{ validation.firstError('hoek') }}</span>
          </div>
        </div>

        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedFurnishedStatus') }"
          v-if="furnishingStatusRule.allowed"
        >
          <label class="col-12 col-lg-3" for="selectedFurnishedStatusField"
            ><span>{{ $t('listing.furnishingStatus') }}</span>
            <span class="required" v-show="furnishingStatusRule.required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedFurnishedStatus"
              id="selectedFurnishedStatusField"
              v-model="selectedFurnishedStatus"
              :options="furnishedStatuses"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :disabled="readOnly"
            />
            <span class="val-error">{{ validation.firstError('selectedFurnishedStatus') }}</span>
          </div>
        </div>

        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedCertificate') }"
        >
          <label class="col-12 col-lg-3" for="selectedCertificateField">
            <span>Sertifikat Properti</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedCertificate"
              id="selectedCertificateField"
              v-model="selectedCertificate"
              :options="certificates"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :disabled="readOnly"
            />
            <span class="val-error">{{ validation.firstError('selectedCertificate') }}</span>
          </div>
        </div>

        <!--        <div class="form&#45;&#45;group row" :class="{ 'has-error': validation.hasError('youtubeUrl') }">-->
        <!--          <label class="col-12 col-lg-3" for="youtubeUrlField">-->
        <!--            <span>Link Youtube</span>-->
        <!--          </label>-->
        <!--          <div class="col-12 col-lg-6">-->
        <!--            <v-text-field-->
        <!--              id="youtubeUrlField"-->
        <!--              v-model="youtubeUrl"-->
        <!--              type="text"-->
        <!--              name="youtubeUrl"-->
        <!--              outlined-->
        <!--              hide-details-->
        <!--              :readonly="readOnly"-->
        <!--            />-->
        <!--            <span class="val-error">{{ validation.firstError('youtubeUrl') }}</span>-->
        <!--          </div>-->
        <!--        </div>-->

        <videos-section :readOnly="readOnly" ref="videosSection" />
        <facilities-section :readOnly="readOnly" ref="facilitiesSection" />
        <div class="d-flex align-center justify-end">
          <v-btn color="error" class="bottom--button" type="button" @click="openRepost">
            {{ $t('listing.btn.repost') }}
          </v-btn>
          <v-btn v-if="readOnly !== true" color="primary" class="bottom--button" type="submit">
            {{ $t('listing.btn.save') }}
          </v-btn>
        </div>
      </form>
    </div>
  </base-material-card>
</template>

<script>
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { mapState, mapGetters } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
const MapSection = () => import('@/components/data-entry/listing/form/map/map-section.vue');
const PhotoSection = () => import('@/components/data-entry/listing/form/photo-section.vue');
const RegionalSection = () => import('@/components/data-entry/listing/form/regional-section.vue');
const UnitNumberingSection = () =>
  import('@/components/data-entry/listing/form/unit-numbering-section.vue');
const UnitDetailSection = () =>
  import('@/components/data-entry/listing/form/unit-detail-section.vue');
const VideosSection = () => import('@/components/data-entry/listing/form/video/videos-section.vue');
const FacilitiesSection = () =>
  import('@/components/data-entry/listing/form/facility/facilities-section.vue');
import CheckYoutubeUrlClass from '@/validations/checkYoutubeUrl';

export default {
  name: 'listing-form',
  mixins: [HelperMixin],
  props: ['readOnly'],
  components: {
    PageLoading,
    Multiselect,
    MapSection,
    PhotoSection,
    RegionalSection,
    UnitNumberingSection,
    UnitDetailSection,
    VideosSection,
    FacilitiesSection,
  },
  data: () => ({
    openSnackbar: false,
  }),
  computed: {
    ...mapState({
      formType: (state) => state.listing.form.formType,
      uuid: (state) => state.listing.form.uuid,
      propertyTypes: (state) => state.listing.form.propertyTypes,
      certificates: (state) => state.listing.form.certificates,
      furnishedStatuses: (state) => state.listing.form.furnishedStatuses,
      listingNumber: (state) => state.listing.form.listingNumber,
      cleaveOptionDecimal: (state) => state.global.cleaveOptionDecimal,
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      cleaveOptionNumber: (state) => state.global.cleaveOptionNumber,
    }),
    ...mapGetters({
      hoekRule: 'listing/form/hoekRule',
      furnishingStatusRule: 'listing/form/furnishingStatusRule',
    }),
    loading: {
      get() {
        return this.$store.state.listing.form.loading;
      },
      set(value) {
        this.$store.commit('listing/form/SET_LOADING', value);
      },
    },
    listingName: {
      get() {
        return this.$store.state.listing.form.listingName;
      },
      set(value) {
        this.$store.commit('listing/form/SET_LISTING_NAME', value);
      },
    },
    listingDesc: {
      get() {
        return this.$store.state.listing.form.listingDesc;
      },
      set(value) {
        this.$store.commit('listing/form/SET_LISTING_DESC', value);
      },
    },
    address: {
      get() {
        return this.$store.state.listing.form.address;
      },
      set(value) {
        this.$store.commit('listing/form/SET_ADDRESS', value);
      },
    },
    price: {
      get() {
        return this.$store.state.listing.form.price;
      },
      set(value) {
        this.$store.commit('listing/form/SET_PRICE', value);
      },
    },
    hoek: {
      get() {
        return this.$store.state.listing.form.hoek;
      },
      set(value) {
        this.$store.commit('listing/form/SET_HOEK', value);
      },
    },
    selectedPropertyType: {
      get() {
        return this.$store.state.listing.form.selectedPropertyType;
      },
      set(value) {
        this.$store.commit('listing/form/SET_SELECTED_PROPERTY_TYPE', value);
      },
    },
    selectedCertificate: {
      get() {
        return this.$store.state.listing.form.selectedCertificate;
      },
      set(value) {
        this.$store.commit('listing/form/SET_SELECTED_CERTIFICATE', value);
      },
    },
    selectedFurnishedStatus: {
      get() {
        return this.$store.state.listing.form.selectedFurnishedStatus;
      },
      set(value) {
        this.$store.commit('listing/form/SET_SELECTED_FURNISHED_STATUS', value);
      },
    },
    categoryName: {
      get() {
        return this.$store.state.listing.form.categoryName;
      },
      set(val) {
        this.$store.commit('listing/form/SET_CATEGORY_NAME', val);
      },
    },
    youtubeUrl: {
      get() {
        return this.$store.state.listing.form.youtubeUrl;
      },
      set(val) {
        this.$store.commit('listing/form/SET_YOUTUBE_URL', val);
      },
    },
  },
  validators: {
    selectedPropertyType(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.selectedPropertyType.required'),
      );
    },
    listingName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.listingName.required'))
        .lengthBetween(
          5,
          70,
          this.$i18n.t('errors.addListing.listingName.between', {
            minChar: 5,
            maxChar: 70,
          }),
        );
    },
    listingDesc(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.listingDesc.required'))
        .lengthBetween(
          10,
          2000,
          this.$i18n.t('errors.addListing.listingDesc.between', {
            minChar: 10,
            maxChar: 2000,
          }),
        );
    },
    price(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.price.required'))
        .between(
          1000,
          999999999999,
          this.$i18n.t('errors.addListing.price.between', {
            minChar: 1000,
            maxChar: 999999999999,
          }),
        );
    },
    hoek(value) {
      if (!this.hoekRule.allowed || !this.hoekRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.addListing.hoek.required'));
    },
    selectedFurnishedStatus(value) {
      if (!this.furnishingStatusRule.allowed || !this.furnishingStatusRule.required) return null;
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.selectedFurnishedStatus.required'),
      );
    },
    selectedCertificate(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.selectedCertificate.required'),
      );
    },
    address(value) {
      return Validator.value(value).maxLength(
        100,
        this.$i18n.t('errors.addListing.address.max', { maxChar: 100 }),
      );
    },
    categoryName(value) {
      return Validator.value(value).maxLength(
        30,
        this.$i18n.t('errors.addListing.categoryName.max', { maxChar: 30 }),
      );
    },
    youtubeUrl(value) {
      const checkYoutubeUrl = new CheckYoutubeUrlClass(value, this.$i18n);
      return Validator.value(value).custom(checkYoutubeUrl.isValidFunc);
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.regionalSection.validate());
      validationArray.push(this.$refs.mapSection.validate());
      validationArray.push(this.$refs.photoSection.validate());
      validationArray.push(this.$refs.unitNumberingSection.validate());
      validationArray.push(this.$refs.unitDetailSection.validate());
      validationArray.push(this.$refs.videosSection.validate());
      validationArray.push(this.$refs.facilitiesSection.validate());
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('listing/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('listing.publish.successMsg'),
          'success',
        );
        this.$router.back();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.openSnackbar = true;
    },
    async openRepost() {
      const result = await this.$swal({
        title: 'Konfirmasi Repost',
        text: `Apakah Anda yakin ingin repost listing dengan nomor ${this.listingNumber}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#00AA5B',
        cancelButtonColor: '#C6252B',
        confirmButtonText: 'Iya, repost.',
        cancelButtonText: 'Tidak, jangan repost.',
        reverseButtons: true,
      });

      if (result.value) {
        let uuid = this.$route.params.uuid;
        console.log('REPOST LISTING UUID: ', uuid);
        try {
          await this.$store.dispatch('listing/repost', uuid);
          this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.repost.successMsg'),
            'success',
          );
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    },
  },
};
</script>

<style scoped></style>
